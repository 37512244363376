import { motion, useIsPresent, Variants } from "framer-motion";
import { useEffect, useState } from "react";

const Profile = ({ data }: any) => {
  const getRandomColor = () => {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const isPresent = useIsPresent();
  const educationSection = data.education
    ? data.education.map(function (education: any) {
        return (
          <div key={education.school}>
            <h3>{education.school}</h3>
            <p className="info">
              {education.degree} <span>&bull;</span>
              <em className="date">{education.graduated}</em>
            </p>
            
          </div>
        );
      })
    : null;
     
    const workSection = data.work
    ? data.work.map(function (work: any, index: any) {
        return (
          <div key={index}>
            <h3>{work.company}</h3>
            <p className="info">
              {work.position} <span>&bull;</span>
              <em className="date">{work.year}</em>
            </p>
            <p className="work-description">{work.description}</p>
          </div>
        );
      })
    : null;

    const certificateSection = data.certificate
    ? data.certificate.map(function (certificate: any, index: any) {
        return (
          <div key={index}>
            <h3>{certificate}</h3>
          </div>
        );
      })
    : null;

  const skillSection = data.skills
    ? data.skills.map((skills: any) => {
        const backgroundColor = getRandomColor();
        const className = "bar-expand " + skills.name.toLowerCase();
        const width = skills.level;

        return (
          <li key={skills.name}>
            <span
              style={{ width, backgroundColor }}
              className={className}
            ></span>
            <em>{skills.name}</em>
          </li>
        );
      })
    : null;

  return (
    <section className="profile-container">
      <div
        style={{
          overflowY: "scroll",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          paddingRight: 15,
          paddingLeft: 15,
        }}
      >
        <div className="description" style={{ minHeight: "50vh" }}>
          <h2>
            Hi, my name is{" "}
            <span
              style={{
                padding: 5,
                backgroundColor: "beige",
                boxShadow: "black 4px 4px",
                borderRadius: 14,
              }}
            >
              Son
            </span>
            {" ."} I'm a web/app developer. <br />
            Original from Vietnam 🇻🇳, now based in Halifax, Canada 🇨🇦.{" "}
          </h2>
        </div>

        <motion.div
          className="education-wrapper"
          animate={{
            x: "100%",
          }}
          whileInView={{
            x: 0,
            transition: {
              duration: 1,
              ease: [0.87, 0, 0.13, 1],
            },
          }}
        >
          <div style={{ width: "20%" }}>
            <h3 className="title">
              <span>Work History</span>
            </h3>
          </div>
          <div className="content">
            {workSection}
          </div>
        </motion.div>

        <motion.div
          className="education-wrapper"
          animate={{
            x: "100%",
          }}
          whileInView={{
            x: 0,
            transition: {
              duration: 1,
              ease: [0.87, 0, 0.13, 1],
            },
          }}
        >
          <div style={{ width: "20%" }}>
            <h3 className="title">
              <span>Skills</span>
            </h3>
          </div>
          <div className="bars">
            <ul className="skills">{skillSection}</ul>
          </div>
        </motion.div>

        
        <motion.div className="education-wrapper">
          <div style={{ width: "20%" }}>
            <h3 className="title">
              <span>Education</span>
            </h3>
          </div>
          <div className="content" style={{ flex: 1 }}>
            {educationSection}
          </div>
        </motion.div>



        <motion.div
          className="education-wrapper"
        >
          <div style={{ width: "20%" }}>
            <h3 className="title">
              <span>Certificates</span>
            </h3>
          </div>
          <div className="certificates">
            {certificateSection}
          </div>
        </motion.div>

        
        

        

        <motion.div
          initial={{ scaleX: 1 }}
          animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circIn" } }}
          exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circOut" } }}
          style={{ originX: isPresent ? 0 : 1 }}
          className="privacy-screen"
        />
      </div>
    </section>
  );
};

export default Profile;
