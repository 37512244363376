import React, { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
  useRoutes,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import $ from "jquery";

//pages
import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";
import Profile from "./pages/Profile";

//menu
import MobileMenu from "./components/Menu/MobileMenu";
//animations
import InitialTransition from "./animation/InitialTransition";

function App() {

  const [data, updateData] = useState({resume:{}, portfolio:{}});
  useEffect(() => {
    
      $.ajax({
        url: "./resumeData.json",
        dataType: "json",
        cache: false,
        success: function(data : any) {
          updateData(data)
        },
        error: function(xhr, status, err) {
          console.log(err);
          alert(err);
        }
      });
    
    return () => {
      updateData({resume:{}, portfolio: {}});
    };
  }, []);

  const element = useRoutes([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/portfolio",
      element: <Portfolio data={data.portfolio} />,
    },
    {
      path: "/about",
      element: <Profile data={data.resume} />,
    },
  ]);

  const location = useLocation();

  if (!element) return null;

  return (
    <>
      <InitialTransition />

      <MobileMenu />

      <AnimatePresence mode="wait" initial={false}>
        {React.cloneElement(element, { key: location.pathname })}
      </AnimatePresence>
    </>
  );
}

export default App;
