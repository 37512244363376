import { motion } from "framer-motion";

const blackBox = {
  initial: {
    height: "100vh",
    bottom: 0,
  },
  animate: {
    height: 0,
    transition: {
      when: "afterChildren",
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
};

const textContainer = {
  initial: {
    opacity: 1,
  },
  animate: {
    opacity: 0,
    transition: {
      duration: 0.25,
      when: "afterChildren",
    },
  },
};

const text = {
  initial: {
    y: "50%",
  },
  animate: {
    y: "60%",
    transition: {
      duration: 2,
      ease: [0.87, 0, 0.13, 1],
    },
  },
};

const InitialTransition = () => {
  return (
    <div
      id="animation-overlay"
      className="absolute inset-0 flex items-center justify-center"
    >
      <motion.div
        className="relative z-50 w-full bg-black"
        initial="initial"
        animate="animate"
        variants={blackBox}
        onAnimationStart={() => document.body.classList.add("overflow-hidden")}
        onAnimationComplete={() => {
          document.body.classList.remove("overflow-hidden");
          document
            .getElementById("animation-overlay")
            ?.classList.remove("absolute");
        }}
      >
        <motion.svg
          variants={textContainer}
          className="absolute z-50 flex w-full h-full"
        >
          <pattern
            id="pattern"
            patternUnits="userSpaceOnUse"
            width={750}
            height={800}
            className="text-white"
          >
            <rect className="w-full h-full fill-current" />
            <motion.rect
              variants={text}
              className="w-full h-full text-gray-600 fill-current"
            />
          </pattern>
          <text
            className="text-3xl font-bold"
            textAnchor="middle"
            x="50%"
            y="50%"
            style={{ fill: "url(#pattern)" }}
          >
            Hi, my name is Son.
          </text>
          <text
            className="text-3xl font-bold"
            textAnchor="middle"
            x="50%"
            y="60%"
            style={{ fill: "url(#pattern)" }}
          >
            And this is my portfolio.
          </text>
        </motion.svg>
      </motion.div>
    </div>
  );
};

export default InitialTransition;
