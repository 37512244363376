import * as React from "react";
import { useEffect, useRef } from "react";
import { motion, useCycle } from "framer-motion";
import { useDimensions } from "./use-dimensions";
import { MenuToggle } from "./MenuToggle";
import { Navigation } from "./MenuNavigation";
import { useLocation } from "react-router-dom";



const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 100vw 0px)`,
    transition: {
      type: "spring",
      stiffness: 25,
      restDelta: 2
    }
  }),
  closed: {
    clipPath: "circle(0px at 100vw 0px)",
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
};

export const MobileMenu = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);
  const {pathname} = useLocation();

  useEffect(() => {
    toggleOpen(0);
    
  }, [ pathname ]);
  



  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      ref={containerRef}
      className={isOpen? "menu-nav active": "menu-nav"}
    >
      <motion.div className="background" variants={sidebar} />
      <Navigation />
      <MenuToggle toggle={() => toggleOpen()} />
    </motion.nav>
  );
};


export default MobileMenu;