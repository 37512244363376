import { motion, useIsPresent } from "framer-motion";
import GridLines from "react-gridlines";
import { Link } from "react-router-dom";
import Background from "../city_background.jpg";

const Home = () => {
  const isPresent = useIsPresent();
  const name = "BLUEprint";
  const name2 = "depLOYment";
  const description =
    "“ I want to make an app that both works and looks great ”";

  return (
    <section className="home-container">
      <motion.div className="home-card">
        <div className="plane"></div>
        <div className="author-text">SON PHAM</div>
        <div className="banner-text">
          <div>
            <h1>
              <div className="italic-headline">from</div>
              <motion.div whileHover={{ scale: 1.1 }} className="outline-text">
                <svg viewBox="0 0 600 100">
                  <symbol id="s-text">
                    <text text-anchor="middle" x="50%" y="80%">
                      {name}{" "}
                    </text>
                  </symbol>

                  <g className="g-ants">
                    <use xlinkHref="#s-text" className="text-copy"></use>
                    <use xlinkHref="#s-text" className="text-copy"></use>
                    <use xlinkHref="#s-text" className="text-copy"></use>
                    <use xlinkHref="#s-text" className="text-copy"></use>
                    <use xlinkHref="#s-text" className="text-copy"></use>
                  </g>
                </svg>
              </motion.div>
            </h1>
          </div>

          <div>
            <h1>
              <div className="italic-headline">to</div>
              <motion.div
                whileHover={{ scale: 1.1 }}
                className="background-text"
              >
                {name2}
              </motion.div>
            </h1>
          </div>

          <div>
            <h3>{description}</h3>
          </div>
        </div>

        <div className="button-wrapper">
          <Link to="/portfolio" className="home-button">
            Portfolio
          </Link>
          <Link to="/profile" className="home-button">
            About Me
          </Link>
          <Link
            to="#"
            onClick={(e) => {
              window.location.href = "mailto:ptson.90@gmail.com";
              e.preventDefault();
            }}
            className="home-button"
          >
            Contact
          </Link>
        </div>
      </motion.div>
      <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circIn" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circOut" } }}
        style={{ originX: isPresent ? 0 : 1 }}
        className="privacy-screen"
      />
      <div className="background-city"></div>
    </section>
  );
};

export default Home;
