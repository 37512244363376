import * as React from "react";
import { motion } from "framer-motion";
import {
  Link
} from "react-router-dom";

const variants = {
  open: {
    duration: 1000,
    y: 0,
    opacity: 1,
  },
  closed: {
    y: 50,
    opacity: 0
  },
};


const itemData = [
  { color: "#f3437b", label: "Home", href: "/" },
  { color: "#41b6ca", label: "Portfolio", href: "/portfolio" },
  { color: "#f2b16a", label: "About", href: "/about" },
];

export const MenuItem = ({ i }: any) => {
  const style = { color: `${itemData[i].color}` };
  return (
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      <Link className="text-placeholder" style={style} to={itemData[i].href}>
        {itemData[i].label}
      </Link>
    </motion.li>
  );
};
