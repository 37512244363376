import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  MotionValue,
  useIsPresent,
} from "framer-motion";
import { useRef, useState } from "react";
import InitialTransition from "../animation/InitialTransition";
// Import Swiper React components
import SwiperCore, { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation, Autoplay } from "swiper";

const useParallax = (value: MotionValue<number>, distance: number) => {
  return useTransform(value, [0, 1], [-distance / 2, distance]);
};

const Image = ({ work, carouselRef, index }: any) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    container: carouselRef,
  });
  const { innerWidth: width, innerHeight: height } = window;
  const y = useParallax(scrollYProgress, width > 500 ? 50 : 200);
  const circley = useParallax(scrollYProgress, width > 500 ? 100 : 300);
  let opacity = useTransform(scrollYProgress, [0, 0.5], [0, 2]);

  return (
    <section>
      <h2>{`#00${index + 1} ${work.company}`}</h2>
      <div
        ref={ref}
        className={`image-wrapper ${
          work.type == "app" ? "vertical" : "default"
        } `}
      >
        <Swiper
          pagination={{
            type: "progressbar",
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
          autoplay={{
            delay: 4000,
            disableOnInteraction: true,
          }}
          loop = {true}
        >
          {work.image.map((img: any, index: any) => {
            return (
              <SwiperSlide key={work.slug + work.key + index}>
                <p>{work.slug + work.key + index}</p>
                <img src={img} alt="work.company" />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>

      <motion.div className="project-content">
        <h3>{`${work.company}`}</h3>
        <p>{work.description}</p>
        <p>{work.tech}</p>
        <div className="links">
          {work.link.map((item: any, index: any) => {
            return (
              <a
                key={"link" + work.slug + work.key + index}
                href={item.href}
                target="_blank"
              >
                {"<< "}
                {item.label}
                {" >>"}
              </a>
            );
          })}
        </div>
      </motion.div>
      <motion.div
        className={`project-bg-square ${
          work.type == "app" ? "vertical" : "default"
        }`}
        style={{ y }}
      >
      </motion.div>
      <motion.div
        className={`project-bg-circle`}
        style={{ y : circley }}
      >
      </motion.div>
      <motion.div
        className={`project-bg-circle-small`}
        style={{ y: circley }}
      >
      </motion.div>
    </section>
  );
};

const Portfolio = ({ data }: any) => {
  const carouselRef = useRef(null);
  const isPresent = useIsPresent();
  const { scrollYProgress } = useScroll({ container: carouselRef });
  const backgroundColor = useTransform(
    scrollYProgress,
    (v) => `rgb(0,153,${230 * Math.max(1 - v, 0.5)})`
  );
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  const workPortfolio = data.work
    ? data.work.map((work: any, index: any) => (
        <Image
          work={work}
          carouselRef={carouselRef}
          index={index}
          key={work.slug + work.key}
        />
      ))
    : null;

  const sideWorkPortfolio = data.workside
    ? data.workside.map((work: any, index: any) => (
        <Image
          work={work}
          carouselRef={carouselRef}
          index={index}
          key={work.slug + work.key}
        />
      ))
    : null;

  return (
    <>
      <motion.div
        style={{
          overflowY: "scroll",
          overflowX: "hidden",
          height: "100vh",
          width: "100vw",
          backgroundColor,
        }}
        ref={carouselRef}
        className="portfolio-container"
      >
        <div style={{ height: "10vh", marginTop: "30vh" }}>
          <div style={{ fontSize: 50, color: "beige", textAlign: "center" }}>
            <span
              style={{
                background: "beige",
                color: "black",
                boxShadow: "black 10px 10px",
                padding: 25,
                borderRadius: 15,
              }}
            >
              My portfolio
            </span>
          </div>
        </div>
        <div style={{ paddingTop: 100, paddingBottom: 200 }}>
          <div style={{ fontSize: 35, color: "beige", textAlign: "center" }}>
            Work projects
          </div>
          {workPortfolio}

          <div style={{ fontSize: 35, color: "beige", textAlign: "center" }}>
            Side projects / School projects
          </div>
          {sideWorkPortfolio}

          <div style={{ fontSize: 35, color: "beige", textAlign: "center" }}>
            Other
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="other-project-content">
              {data.workother &&
                data.workother.map((item: any, index: any) => {
                  return (
                    <div key={item.key}>
                      <h3>{item.company}</h3>
                      <p style={{ whiteSpace: "pre-line", marginBottom: 10 }}>
                        {item.description}
                      </p>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </motion.div>
      <motion.div
        style={{
          scaleX: scrollYProgress,
          height: 5,
          position: "fixed",
          bottom: 20,
          left: "10vw",
          width: "80vw",
          backgroundColor: "beige",
          zIndex: 9,
        }}
      />
      <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circIn" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circOut" } }}
        style={{ originX: isPresent ? 0 : 1 }}
        className="privacy-screen"
      />
    </>
  );
};

export default Portfolio;
